import config from '../1config';
import { createStore } from 'vuex';
import hardwares from './hardwares';
import members from './members';
import residents from './residents';
import secretIds from './secretIds';
import secrets from './secrets';

console.log('✌️ 4services loaded');

export default createStore({
  modules: {
    ...hardwares,
    ...members,
    ...residents,
    ...secretIds,
    ...secrets,
  },
  strict: config.env === 'dev',
});
