export default {
  openNewTab: function (url) {
    let win = window.open(url, '_blank');
    // Browser has allowed it to be opened
    if (win) {
      win.focus();
      return;
    }
    // Browser has blocked it
    throw new Error('Please allow popups for this website');
  },
  openNewPopupHTML: function (htmlDoc) {
    let win = window.open(
      '',
      'info',
      'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no'
    );
    // Browser has allowed it to be opened
    if (win) {
      win.document.body.innerHTML = htmlDoc;
      win.focus();
      return;
    }
    // Browser has blocked it
    throw new Error('Please allow popups for this website');
  },
  getPrintIFrameElement: function () {
    const elementID = 'print-iframe';
    let element = document.getElementById(elementID);
    // CASE 1 - Already appended
    if (element) {
      return element;
    }
    // CASE 2 - Not yet appended
    element = document.createElement('iframe');
    element.id = elementID;
    element.style.display = 'none';
    element.onload = function () {
      setTimeout(function () {
        element.focus();
        element.contentWindow.print();
      }, 1);
    };
    document.body.appendChild(element);
    return element;
  },
  removePrintIFrameElement: function () {
    const elementID = 'print-iframe';
    let element = document.getElementById(elementID);
    if (!element) return;
    element.parentNode.removeChild(element);
  },
  printURLwPDF: function (url) {
    const iframe = this.getPrintIFrameElement();
    iframe.src = url;
  },
};
