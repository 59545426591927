import rpi_revisions from './rpi_revisions';

// STATE - default
let initialState = {};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {};

// ACTIONS
const actions = {};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
};

export default {
  ...rpi_revisions,
  hardwares: {
    state,
    getters,
    actions,
    mutations,
  },
};
