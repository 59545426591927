import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import startFirebaseAuthUI from './firebase_auth_ui';

const firebaseConfig = {
  apiKey: 'AIzaSyArx_JJ9ZsPuGcFzzs5kVGVotMwMHbna0A',
  authDomain: 'chekt-app-fty-prod.firebaseapp.com',
  projectId: 'chekt-app-fty-prod',
  messagingSenderId: '1002293501602',
  appId: '1:1002293501602:web:14d85247347e7a153d41fb',
  measurementId: 'G-V9TBHWTYKE',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const authObj = {
  idToken: '',
  isAuthenticated: false,
  uid: '',
  email: '',
  creationTime: '',
  lastSignInTime: '',
  reset: function () {
    this.idToken = '';
    this.isAuthenticated = false;
    this.uid = '';
    this.email = '';
    this.creationTime = '';
    this.lastSignInTime = '';
  },
  getAccessToken: function () {
    return this.idToken;
  },
};

let prevExpiredAt = 0;
const updateIDToken = async function (forceRefresh) {
  const token = await auth.currentUser.getIdTokenResult(forceRefresh);
  authObj.idToken = token.token;
  const now = new Date().getTime();
  const expiredAt = new Date(token.expirationTime).getTime();
  if (prevExpiredAt == expiredAt) return;
  prevExpiredAt = expiredAt;
  console.log(
    `✌️ 2loaders.firebase_auth: idToken is valid for next ${(
      (expiredAt - now) /
      1000 /
      60
    ).toFixed(2)} minutes`
  );
};

const startRefreshingIDToken = function () {
  console.log('✌️ 2loaders.firebase_auth: startRefreshingIDToken excuted');
  setInterval(async () => {
    if (!authObj.isAuthenticated) return;
    await updateIDToken(false);
  }, 1000 * 10);
};

onAuthStateChanged(auth, async (user) => {
  // CASE 1 - signed out
  if (!user) {
    console.log('✌️ 2loaders.firebase_auth: CASE 1 - signed out');
    authObj.reset();
    startFirebaseAuthUI({ auth });
    return;
  }
  // CASE 2 - signed in
  console.log('✌️ 2loaders.firebase_auth: CASE 2 - signed in');
  // User is signed in, see docs for a list of available properties
  // https://firebase.google.com/docs/reference/js/firebase.User
  await updateIDToken(false);
  authObj.isAuthenticated = true;
  authObj.uid = user.uid;
  authObj.email = user.email;
  authObj.creationTime = user.metadata?.creationTime;
  authObj.lastSignInTime = user.metadata?.lastSignInTime;
});

console.log('✌️ 2loaders.firebase_auth loaded');
startRefreshingIDToken();

export default authObj;
