<template lang="pug">
loadResidents
</template>

<style>
</style>

<script>
import loadResidents from "./loadResidents.vue";

export default {
  components: {
    loadResidents,
  },
};
</script>
