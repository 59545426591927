import resident from './resident';
import ims from '../imports';

export default {
  ...resident,
  getResidents: function ({ secret_id, mac }) {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/residents`,
      params: { secret_id, mac },
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
