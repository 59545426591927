import axios from 'axios';
import tool from '../0lib/tool';
import config from '../1config';
import firebase_auth from '../2loaders/firebase_auth';

export default {
  axios,
  tool,
  config,
  firebase_auth,
};
