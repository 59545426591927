<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  created: function () {
    console.log("✌️ 7subscribers.events loaded");
    window.removeEventListener("c-export-file", this.handleExportFile);
    window.addEventListener("c-export-file", this.handleExportFile);
  },
  methods: {
    handleExportFile: function (event) {
      if (event?.detail?.status !== true) {
        return this.$q.notify({
          message: `Browser denied file(${event?.detail?.filename}) download...`,
          color: "negative",
          icon: "warning",
        });
      }
      this.$q.notify({
        message: `${event?.detail?.filename} has been downloaded.`,
        color: "primary",
        icon: "check_circle",
      });
    },
  },
};
</script>
