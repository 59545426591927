import ims from '../../imports';
import rpi_revision from './rpi_revision';

export default {
  ...rpi_revision,
  getRPIRevisions: function () {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/hardwares/rpi_revisions`,
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
  createRPIRevision: function ({ data }) {
    return ims.axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/hardwares/rpi_revisions`,
      data: ims.tool.removeEmptyKey({
        rpi_revision: data?.rpi_revision,
        hw_version: data?.hw_version,
        model: data?.model,
        model_public: data?.model_public,
        note: data?.note,
        product_code: data?.product_code,
      }),
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
