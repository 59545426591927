<template lang="pug">
q-table(
  :rows="rpi_revisions",
  :columns="columns",
  color="primary",
  row-key="rpi_revision",
  :filter="filter",
  :visible-columns="visibleColumns",
  :pagination="pagination",
  :rows-per-page-options="[20, 50, 0]"
)
  template(v-slot:top-left)
    q-input(
      outlined,
      dense,
      debounce="300",
      v-model="filter",
      placeholder="Search",
      style="width: 250px"
    )
      template(v-slot:append)
        q-icon(name="search")

  template(v-slot:top-right)
    .flex.q-gutter-sm.q-mb-sm
      q-btn(
        outline,
        color="primary",
        icon="add",
        label="New",
        @click="openCreateRPIRevisionDialog"
      )
      q-btn(
        outline,
        color="primary",
        icon="refresh",
        label="Refresh",
        :loading="loading",
        @click="getRPIRevisions"
      )

  template(v-slot:header="props")
    q-tr(:props="props")
      q-th
      q-th(v-for="col in props.cols", :key="col.name", :props="props")
        | {{ col.label }}

  template(v-slot:body="props")
    q-tr(:props="props")
      q-td
        q-btn(
          color="primary",
          dense,
          flat,
          icon="info_outline",
          @click="openReadRPIRevisionDialog(props?.row?.rpi_revision)"
        )
      q-td(v-for="col in props.cols", :key="col.name", :props="props")
        | {{ col.value }}
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  data: function () {
    return {
      filter: "",
      visibleColumns: [
        "rpi_revision",
        "hw_version",
        "model",
        "model_public",
        "note",
        "product_code",
        "updated_at",
        "updated_by_remote_ip",
        "created_at",
        "created_by_remote_ip",
      ],
      pagination: {
        sortBy: "rpi_revision",
        rowsPerPage: 0,
        descending: false,
      },
      columns: [
        {
          name: "rpi_revision",
          required: true,
          label: "RPi Revision",
          align: "left",
          field: (row) => row.rpi_revision,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "hw_version",
          label: "HW Version",
          field: "hw_version",
          sortable: true,
        },
        {
          name: "model",
          label: "Model",
          field: "model",
          sortable: true,
        },
        {
          name: "model_public",
          label: "Model (Public)",
          field: "model_public",
          sortable: true,
        },
        {
          name: "note",
          label: "Note",
          field: "note",
          sortable: true,
        },
        {
          name: "product_code",
          label: "Product Code",
          field: "product_code",
          sortable: true,
        },
        {
          name: "updated_at",
          label: "Updated At",
          field: "updated_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "updated_by_remote_ip",
          label: "Updated by IP",
          field: "updated_by_remote_ip",
          sortable: true,
        },
        {
          name: "created_at",
          label: "Created At",
          field: "created_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "created_by_remote_ip",
          label: "Created by IP",
          field: "created_by_remote_ip",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    rpi_revisions: function () {
      return Object.values(this.$store.getters.rpi_revisions);
    },
    loading: function () {
      if (this.$store.getters.statusGetRPIRevisions === "requested")
        return true;
      return false;
    },
  },
  mounted: async function () {
    await ims.tool.sleep(1000 * 1);
    await this.getRPIRevisions();
  },
  methods: {
    getRPIRevisions: function () {
      return this.$store.dispatch("getRPIRevisions");
    },
    openCreateRPIRevisionDialog: function () {
      this.$c.dialog("create_rpi_revision");
    },
    openReadRPIRevisionDialog: function (rpi_revision_id) {
      this.$c.dialog("read_rpi_revision", { rpi_revision_id });
    },
  },
};
</script>
