import PDFDocument from 'pdfkit/js/pdfkit.standalone';
import blobStream from 'blob-stream';

export default {
  generatePDFwQRCodes: function ({ layout, qrcodes }) {
    return new Promise((resolve, reject) => {
      try {
        if (layout.paper.size === 'SQUARE') {
          const pSize = layout.qrcode.size + 0.11; // +0.11 is border + 0.01
          layout.paper.size = [pSize, pSize];
        }
        const doc = new PDFDocument({
          size: layout.paper.size,
          margins: layout.paper.margins,
          info: {
            Title: 'CKB QR Code',
            Author: 'fty.chekt.com',
          },
        });
        // pipe the document to a blob
        const stream = doc.pipe(blobStream());

        // ///////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////
        // DRAWINGs
        // ///////////////////////////////////////////////////////////////////
        // INIT - variables
        const pMargins = layout.paper.margins;
        const qrSize = layout.qrcode.size;
        const qrMargin = layout.qrcode.margin;
        const qrGapX = layout.qrcode.gap.x;
        const qrGapY = layout.qrcode.gap.y;
        const drawingW = doc.page.width - pMargins.left - pMargins.right;
        let itemsPerLine = Math.floor(drawingW / qrSize);
        const spaceLeftW = drawingW + qrGapX - (qrSize + qrGapX) * itemsPerLine;
        if (spaceLeftW < 0) itemsPerLine--;
        const qrSizeInner = qrSize - qrMargin * 2;
        qrcodes.forEach((v, i) => {
          // INIT - x, y coordiantes
          const x = (qrSize + qrGapX) * (i % itemsPerLine) + pMargins.left;
          const y =
            (qrSize + qrGapY) * Math.floor(i / itemsPerLine) + pMargins.top;
          // Outline - roundedRect
          const lineWidth = 1;
          if (!v.selected) {
            doc
              .roundedRect(
                x + lineWidth / 2,
                y + lineWidth / 2,
                qrSize - lineWidth,
                qrSize - lineWidth,
                5
              )
              .lineWidth(lineWidth)
              .strokeColor('#000', 0.04)
              .stroke();
          } else {
            doc
              .roundedRect(
                x + lineWidth / 2,
                y + lineWidth / 2,
                qrSize - lineWidth,
                qrSize - lineWidth,
                5
              )
              .fill('#ffc107');
            doc
              .roundedRect(
                x + lineWidth / 2,
                y + lineWidth / 2,
                qrSize - lineWidth,
                qrSize - lineWidth,
                5
              )
              .lineWidth(lineWidth)
              .strokeColor('#000', 1)
              .stroke();
          }
          // QR Code image
          doc.image(v.png, x + qrMargin, y + qrMargin, {
            fit: [qrSizeInner, qrSizeInner],
            // link: 'http://localhost:8080/in-production?mac=mac&entry=entry',
          });
          // INIT - TEXT
          let fontSize = qrMargin * 0.7;
          let fontSizeW = fontSize * 0.6;
          if (fontSizeW * 12 > qrSize) {
            fontSize = qrSize / 12 / 0.6;
            fontSizeW = fontSize * 0.6;
          }
          const textMarginY = (qrMargin - fontSize) / 2;
          doc.font('Courier', fontSize).fill('#000'); // monospaced
          // TEXT - top(mac)
          doc.text(
            v.text_top,
            x + (qrSize - fontSizeW * v.text_top.length) / 2,
            y + textMarginY + 1
          ); // 12 letters
          // TEXT - bottom(entry_key)
          doc.text(
            v.text_bottom,
            x + (qrSize - fontSizeW * v.text_bottom.length) / 2,
            y + qrSize - qrMargin + textMarginY
          ); // 6 letters
        });
        // ///////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////

        // get a blob when you are done
        doc.end();
        stream.on('finish', function () {
          const url = stream.toBlobURL('application/pdf');
          resolve(url);
        });
      } catch (err) {
        reject(err);
      }
    });
  },
  generatePDFwQRCode: function ({ size, margin, qrcode }) {
    const magicNumber = 595.28 / 210;
    return this.generatePDFwQRCodes({
      qrcodes: [qrcode],
      layout: {
        // mm to pixels
        paper: {
          size: 'SQUARE',
          margins: {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
          },
        },
        qrcode: {
          size: size * magicNumber,
          gap: {
            x: 0,
            y: 0,
          },
          margin: margin * magicNumber,
        },
      },
    });
  },
};
