import ims from '../imports';

// STATE - default
let initialState = {
  secretIds: null,
  statusGetSecretIds: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  secretIds: function (state) {
    return state.secretIds ?? [];
  },
  statusGetSecretIds: function (state) {
    return state.statusGetSecretIds;
  },
};

// ACTIONS
const actions = {
  getSecretIds: async function ({ commit, getters }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetSecretIds === 'requested') return;
    commit('GET_SECRET_IDS_REQUEST');
    try {
      const res = await ims.models.getSecretIds();
      commit('GET_SECRET_IDS_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_SECRET_IDS_FAILURE');
      throw new Error(err);
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_SECRET_IDS_REQUEST: function (state) {
    state.statusGetSecretIds = 'requested';
  },
  GET_SECRET_IDS_SUCCESS: function (state, data) {
    state.secretIds = Object.keys(data);
    state.statusGetSecretIds = 'successful';
  },
  GET_SECRET_IDS_FAILURE: function (state) {
    state.statusGetSecretIds = 'failed';
  },
};

export default {
  secretIds: {
    state,
    getters,
    actions,
    mutations,
  },
};
