import basic from './dialog/basic.vue';
import print_qrcode from './dialog/print_qrcode.vue';
import print_qrcodes from './dialog/print_qrcodes.vue';
import resident from './dialog/resident.vue';
import create_rpi_revision from './dialog/create/rpi_revision.vue';
import read_rpi_revision from './dialog/read/rpi_revision.vue';
// ////////////////////////////////////////////////////////
// e.g.
// let dialog = this.$c.dialog('print_qrcodes', {})
//                     .onDismiss(() => {})
//                     .onOk(() => alert('ok'))
//                     .onCancel(() => {})
// setTimeout(() => { dialog.hide() }, 1000)
// ////////////////////////////////////////////////////////

// REGISTER - Dialogs
const dialogs = {
  basic,
  print_qrcode,
  print_qrcodes,
  resident,
  create_rpi_revision,
  read_rpi_revision,
};

import { createApp, h } from 'vue';
export default ({ app }) => {
  const getDialog = function (dialogName) {
    if (!dialogs[dialogName]) throw new Error(`No such dialog, ${dialogName}`);
    return dialogs[dialogName];
  };
  app.config.globalProperties.$c = {
    dialog: function (dialogName, pluginProps) {
      // INIT
      let show = true;
      const el = document.createElement('div');
      document.body.appendChild(el);
      // INIT - API
      const okFns = [];
      const cancelFns = [];
      const API = {
        onOk: function (fn) {
          okFns.push(fn);
          return API;
        },
        onCancel: function (fn) {
          cancelFns.push(fn);
          return API;
        },
        onDismiss: function (fn) {
          okFns.push(fn);
          cancelFns.push(fn);
          return API;
        },
        hide: function () {
          show = false;
          vm.$forceUpdate();
          return API;
        },
      };
      const onOk = (data) => {
        okFns.forEach((fn) => {
          fn(data);
        });
      };
      const onHide = () => {
        childApp.unmount(el);
        childApp = null;
        cancelFns.forEach((fn) => {
          fn();
        });
        el.remove();
      };
      // CREATE - childApp
      let childApp = createApp({
        // name: 'CGlobalDialog',
        render: function () {
          return h(getDialog(dialogName), {
            modelValue: show,
            ...pluginProps,
            onOk,
            onHide,
          });
        },
      });
      // LINK - childApp --- rootApp
      childApp.config.globalProperties = app.config.globalProperties;
      Object.assign(childApp._context, app._context);
      // MOUNT - childApp
      let vm = childApp.mount(el);
      return API;
    },
  };
};
