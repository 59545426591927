import ims from '../../imports';

export default {
  getResident: function ({ residentId }) {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/residents/${residentId}`,
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
  updateResident: function ({ residentId, data }) {
    return ims.axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/residents/${residentId}`,
      data: ims.tool.removeEmptyKey({
        env: data?.env,
        action_blink_leds: data?.action_blink_leds,
        action_manufacturing_done: data?.action_manufacturing_done,
      }),
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
