<template lang="pug">
q-input(stack-label, filled)
</template>

<style>
</style>

<script>
export default {};
</script>
