<template lang="pug">
div
  q-input(
    v-model.number="qrcodeSize",
    type="number",
    stack-label,
    filled,
    label="qrcode.size (mm)",
    color="grey-9"
  )
  q-input(
    v-model.number="qrcodeMargin",
    type="number",
    stack-label,
    filled,
    label="qrcode.margin (mm)",
    color="grey-9"
  )
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  props: {
    mac: String,
    entry: String,
  },
  emits: ["update:qrcode", "update:pdfurl"],
  data: function () {
    return {
      pdfurl: "",
      qrcode: {},
      qrcodeSize: 40,
      qrcodeMargin: 6,
    };
  },
  watch: {
    mac: function () {
      this.updateQRCode();
    },
    entry: function () {
      this.updateQRCode();
    },
    qrcode: function () {
      this.generatePDF();
    },
    qrcodeSize: function (val) {
      ims.config.updateLocalConfig("qrcodeSize", val);
      this.generatePDF();
    },
    qrcodeMargin: function (val) {
      ims.config.updateLocalConfig("qrcodeMargin", val);
      this.generatePDF();
    },
  },
  mounted: function () {
    this.init();
    this.updateQRCode();
  },
  methods: {
    init: function () {
      if (ims.config.localConfig.qrcodeSize !== undefined) {
        this.qrcodeSize = ims.config.localConfig.qrcodeSize;
      }
      if (ims.config.localConfig.qrcodeMargin !== undefined) {
        this.qrcodeMargin = ims.config.localConfig.qrcodeMargin;
      }
    },
    updateQRCode: async function () {
      if (!this.mac) return;
      if (!this.entry) return;
      this.qrcode = ims.tool.generateQRCodePNGwCKB({
        mac: this.mac,
        entry: this.entry,
      });
      this.$emit("update:qrcode", this.qrcode);
    },
    generatePDF: async function () {
      const size = this.qrcodeSize;
      const margin = this.qrcodeMargin;
      const qrcode = this.qrcode;
      this.pdfurl = await ims.tool.generatePDFwQRCode({ size, margin, qrcode });
      this.$emit("update:pdfurl", this.pdfurl);
    },
  },
};
</script>
