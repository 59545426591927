import secret from './secret';
import ims from '../imports';

export default {
  ...secret,
  getSecrets: function () {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/secrets`,
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
