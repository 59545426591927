<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      refreshing$i: null,
    };
  },
  mounted: function () {
    console.log("✌️ 6jobs.loadResidents loaded");
  },
  beforeUnmount: function () {},
  methods: {
    startRefreshing: function () {
      this.stopRefreshing();
      this.refreshing$i = setInterval(() => {
        this.$store.dispatch("getResidents", {});
      }, 1000 * 60 * 1); // 1 minute
    },
    stopRefreshing: function () {
      if (!this.refreshing$i) return;
      clearInterval(this.refreshing$i);
      this.refreshing$i = null;
    },
  },
};
</script>
