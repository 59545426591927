<template lang="pug">
q-dialog(ref="dialog", transition-show="slide-up", transition-hide="slide-down")
  q-card(style="max-width: 900px; min-width: 400px")
    //- [HEAD]
    q-bar.bg-grey-5(style="position: sticky; top: 0px; z-index: 1")
      q-icon(name="info_outline")
      div RPI Revision Information
      q-space
      q-btn(
        dense,
        flat,
        icon="refresh",
        @click="getRPIRevision",
        :loading="loading"
      )
      q-btn(dense, flat, icon="close", v-close-popup)
        q-tooltip Close
    //- [BODY]
    q-card-section.overflow-auto
      //- Readonly
      div
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 Readonly
          .text-body.text-grey-7 To change this, you need to recreate the record.

        input_101(
          v-model="readwrite['rpi_revision']",
          bg-color="green-1",
          label="rpi_revision (key)"
        )

      //- Editable (critical)
      .q-mt-md
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 Editable (critical)
          .text-body.text-grey-7 Edit and update only if you know what you are doing.

        input_102(
          v-model="readwrite['product_code']",
          bg-color="red-1",
          label="product_code (to generate USN of CKB) [5 digits]"
        )
        input_102(
          v-model.number="readwrite['hw_version']",
          bg-color="red-1",
          type="number",
          label="hw_version (to determine CKB IO spec)"
        )
        input_102(
          v-model="readwrite['model']",
          bg-color="red-1",
          label="model (to determine CKB IO spec)"
        )

      //- Editable (non-critical)
      .q-mt-md
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 Editable (non-critical)
          .text-body.text-grey-7 Edit and update whenever you want.

        input_102(
          v-model="readwrite['model_public']",
          bg-color="amber-1",
          label="model_public (not used)"
        )
        input_102(
          v-model="readwrite['note']",
          bg-color="amber-1",
          label="note (just an additional information)"
        )

        q-btn.full-width.q-mt-sm(
          unelevated,
          label="Update",
          color="positive",
          icon="upload",
          :disable="!isChanged",
          @click="onClickUpdate"
        )
</template>

<style>
</style>

<script>
import ims from "../../imports";
import input_101 from "../../input/input_101";
import input_102 from "../../input/input_102";
export default {
  components: {
    input_101,
    input_102,
  },
  props: {
    rpi_revision_id: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  computed: {
    rpi_revision: function () {
      return this.$store.getters.rpi_revision(this.rpi_revision_id);
    },
    loading: function () {
      if (this.$store.getters.statusGetRPIRevision === "requested") return true;
      if (this.$store.getters.statusUpdateRPIRevision === "requested")
        return true;
      return false;
    },
    isChanged: function () {
      return !ims._.isEqual(this.rpi_revision, this.readwrite);
    },
  },
  data: function () {
    return {
      readwrite: {},
    };
  },
  watch: {
    rpi_revision_id: function () {
      this.init();
    },
    rpi_revision: function () {
      this.init();
    },
  },
  mounted: function () {
    this.init();
    this.getRPIRevision();
  },
  methods: {
    init: function () {
      if (!this.rpi_revision) return;
      this.readwrite = JSON.parse(JSON.stringify(this.rpi_revision));
    },
    //
    getRPIRevision: function () {
      if (!this.rpi_revision_id) return;
      return this.$store.dispatch("getRPIRevision", {
        rpi_revision_id: this.rpi_revision_id,
      });
    },
    //
    onClickUpdate: function () {
      this.$q
        .dialog({
          title: `Update hardware information`,
          message: "Are you sure to update the hardware information?",
          ok: {
            flat: true,
            color: "negative",
          },
          cancel: {
            flat: true,
            color: "black",
          },
          focus: "cancel",
        })
        .onOk(() => this.updateRPIRevision(this.readwrite));
    },
    updateRPIRevision: function ({
      hw_version,
      model,
      model_public,
      note,
      product_code,
    }) {
      if (!this.rpi_revision_id) return;
      return this.$store.dispatch("updateRPIRevision", {
        rpi_revision_id: this.rpi_revision_id,
        data: {
          hw_version,
          model,
          model_public,
          note,
          product_code,
        },
      });
    },
  },
};
</script>
