import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { EmailAuthProvider } from 'firebase/auth';

const uiElementID = 'firebaseui-auth-container';

const getAuthUIElement = function () {
  let uiElement = document.getElementById(uiElementID);
  // CASE 1 - Already appended
  if (uiElement) {
    return uiElement;
  }
  // CASE 2 - Not yet appended
  uiElement = document.createElement('div');
  uiElement.id = uiElementID;
  uiElement.style.cssText =
    'position:absolute;top:0;left:0;width:100%;height:100%;z-index:1000000;backdrop-filter:blur(20px);-webkit-backdrop-filter:blur(20px);';
  document.body.appendChild(uiElement);
  return uiElement;
};

const removeAuthUIElement = function () {
  let uiElement = document.getElementById(uiElementID);
  if (!uiElement) return;
  uiElement.parentNode.removeChild(uiElement);
};

console.log('✌️ 2loaders.firebase_auth_ui loaded');

export default function ({ auth }) {
  const ui = new firebaseui.auth.AuthUI(auth);
  ui.start(getAuthUIElement(), {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult) {
        if (authResult.user) {
          removeAuthUIElement();
        }
        // Do not redirect.
        return false;
      },
    },
    signInFlow: 'popup',
    // signInSuccessUrl: '/tabs/appointment',
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        disableSignUp: {
          status: true,
        },
        // requireDisplayName: false
      },
    ],
  });
}
