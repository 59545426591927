import string from './string';

export default {
  isIP: function (ip) {
    if (!string.isString(ip)) return false;
    if (ip.split('.').length !== 4) return false;
    return true;
  },
  padifyIP: function (ip) {
    if (!this.isIP(ip)) {
      return ip;
    }
    const m = String(ip).split('.');
    const padded = [];
    for (let i = 0; i < 4; i++) {
      padded.push(m[i].padStart(3, '0'));
    }
    return padded.join('.');
  },
  extractIPPrefix: function (ip) {
    if (!this.isIP(ip)) {
      return '';
    }
    // FFF: Fixed
    // DDD: Dynamic
    // 192.168.0.0 – 192.168.255.255  (65,536 IP addresses) <-- 192.168.FFF.DDD
    // 172.16.0.0 – 172.31.255.255 (1,048,576 IP addresses) <-- 172.FFF.FFF.DDD
    // 10.0.0.0 – 10.255.255.255  (16,777,216 IP addresses) <-- 010.FFF.FFF.DDD
    const m = String(ip).split('.');
    const padded = [];
    for (let i = 0; i < 3; i++) {
      padded.push(m[i].padStart(3, '0'));
    }
    return padded.join('.');
  },
};
