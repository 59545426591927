<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  watch: {
    "$store.getters.statusGetResidents": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `Devices loaded.`,
            color: "primary",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading devices failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetResident": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `Device loaded.`,
            color: "primary",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading device failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusUpdateResident": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `Device updated.`,
            color: "positive",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading device failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    //
    "$store.getters.statusGetRPIRevisions": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `RPI Revisions loaded.`,
            color: "primary",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading RPI Revisions failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetRPIRevision": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `RPI Revision loaded.`,
            color: "primary",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading RPI Revision failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusUpdateRPIRevision": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `RPI Revision updated.`,
            color: "positive",
            icon: "check_circle",
            timeout: 400,
          });
        case "failed":
          return this.$q.notify({
            message: "Updating RPI Revision failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
  },
  mounted: function () {
    console.log("✌️ 7subscribers.apis loaded");
  },
};
</script>
