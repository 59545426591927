import ims from '../../../imports';

export default {
  getRPIRevision: function ({ rpi_revision_id }) {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/hardwares/rpi_revisions/${rpi_revision_id}`,
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
  updateRPIRevision: function ({ rpi_revision_id, data }) {
    return ims.axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/hardwares/rpi_revisions/${rpi_revision_id}`,
      data: ims.tool.removeEmptyKey({
        hw_version: data?.hw_version,
        model: data?.model,
        model_public: data?.model_public,
        note: data?.note,
        product_code: data?.product_code,
      }),
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
