import dialogLoader from './dialog';
import dtable_devices_in_production from './dtable/devices_in_production.vue';
import dtable_residents from './dtable/residents.vue';
import dtable_rpi_revisions from './dtable/rpi_revisions.vue';
import dtable_secrets from './dtable/secrets.vue';

export default {
  install: function (app) {
    dialogLoader({ app });
    // REGISTER - Components
    app.component(
      'c-dtable-devices-in-production',
      dtable_devices_in_production
    );
    app.component('c-dtable-residents', dtable_residents);
    app.component('c-dtable-rpi-revisions', dtable_rpi_revisions);
    app.component('c-dtable-secrets', dtable_secrets);
  },
};
