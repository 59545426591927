<template lang="pug">
q-header.row.no-wrap(bordered, height-hint="150")
  q-toolbar
    q-icon.q-pr-md.q-pl-xs(name="factory", size="sm")
    .q-ml-md path: {{ $route.path }}
    q-space
    q-btn(flat, round, dense, icon="person")
</template>

<style>
</style>

<script>
export default {};
</script>
