<template lang="pug">
q-drawer.bg-grey-1(show-if-above, bordered, mini, behavior="desktop")
  q-list(style="color: hsla(0, 0%, 0%, 0.38)")
    q-item-label(header) Routes

    q-item(
      clickable,
      tag="router-link",
      v-bind:to="{ path: '/in-production' }",
      active-class="text-primary"
    )
      q-item-section(avatar)
        q-icon(name="conveyor_belt")
          q-tooltip.bg-c-blue-grey-highest(
            anchor="center right",
            self="center left",
            style="font-size: 0.8rem"
          ) In Production

    q-item(
      clickable,
      tag="router-link",
      v-bind:to="{ path: '/residents' }",
      active-class="text-primary"
    )
      q-item-section(avatar)
        q-icon(name="shelves")
          q-tooltip.bg-c-blue-grey-highest(
            anchor="center right",
            self="center left",
            style="font-size: 0.8rem"
          ) Residents

    q-item(
      clickable,
      tag="router-link",
      v-bind:to="{ path: '/members' }",
      active-class="text-primary"
    )
      q-item-section(avatar)
        q-icon(name="people")
          q-tooltip.bg-c-blue-grey-highest(
            anchor="center right",
            self="center left",
            style="font-size: 0.8rem"
          ) Members

    q-item(
      clickable,
      tag="router-link",
      v-bind:to="{ path: '/hardwares' }",
      active-class="text-primary"
    )
      q-item-section(avatar)
        q-icon(name="device_unknown")
          q-tooltip.bg-c-blue-grey-highest(
            anchor="center right",
            self="center left",
            style="font-size: 0.8rem"
          ) Hardwares

    q-item(
      clickable,
      tag="router-link",
      v-bind:to="{ path: '/secrets' }",
      active-class="text-primary"
    )
      q-item-section(avatar)
        q-icon(name="key")
          q-tooltip.bg-c-blue-grey-highest(
            anchor="center right",
            self="center left",
            style="font-size: 0.8rem"
          ) Secrets
</template>

<style>
</style>

<script>
export default {};
</script>
