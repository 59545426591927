<template lang="pug">
q-table(
  :rows="residents",
  :columns="columns",
  color="primary",
  row-key="usn",
  selection="multiple",
  v-model:selected="selected",
  :visible-columns="visibleColumns",
  :pagination="pagination",
  :rows-per-page-options="[10, 20, 0]",
  :loading="loading"
)
  template(v-slot:top-left)
    .flex.q-gutter-sm.q-mb-sm
      q-select(
        outlined,
        dense,
        clearable,
        v-model="secretId",
        :options="secretIds",
        label="Production ID",
        style="width: 200px"
      )
      q-select(
        outlined,
        dense,
        clearable,
        v-model="tableIP",
        :options="tableIPs",
        label="Table IP",
        style="width: 200px"
      )
      q-select(
        outlined,
        dense,
        clearable,
        v-model="timeWindow",
        :options="timeWindows",
        label="Time Window (minutes)",
        style="width: 200px"
      )
      q-select(
        outlined,
        dense,
        clearable,
        v-model="manufacturingStatus",
        :options="manufacturingStatuses",
        label="Manufacturing Status",
        style="width: 200px"
      )

  template(v-slot:top-right)
    .flex.q-gutter-sm.q-mb-sm
      q-btn(
        outline,
        color="primary",
        icon="print",
        label="QR Codes",
        :disable="selected.length < 1",
        @click="openPrintQRCodes"
      )
      q-btn(
        outline,
        color="primary",
        icon="refresh",
        label="Refresh",
        :loading="loading",
        @click="getResidents"
      )

  template(v-slot:header="props")
    q-tr(:props="props")
      q-th
      q-th(auto-width)
        q-checkbox(v-model="props.selected")
      q-th(v-for="col in props.cols", :key="col.name", :props="props")
        | {{ col.label }}

  template(v-slot:body="props")
    q-tr(:props="props")
      q-td
        q-btn(
          color="primary",
          dense,
          flat,
          icon="info_outline",
          @click="openResidentDialog(props?.row?.csn)"
        )
      q-td(auto-width)
        q-checkbox(v-model="props.selected")
      q-td(v-for="col in props.cols", :key="col.name", :props="props")
        template(v-if="col.label === 'Manufacturing Done'")
          span.bg-warning.text-black.q-py-xs.q-px-sm.rounded-borders(
            v-if="col.value === 'in-process'"
          ) {{ col.value }}
          span.bg-positive.text-black.q-py-xs.q-px-sm.rounded-borders(
            v-else-if="col.value === 'completed'"
          ) {{ col.value }}
        template(v-else)
          | {{ col.value }}
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  data: function () {
    return {
      selected: [],
      visibleColumns: [
        "entry_key",
        "macs.eth0",
        "usn",
        "hw_version",
        "model",
        "firmware",
        "ips.eth0.address",
        "ips.eth1.address",
        "ping_count",
        "pinged_at",
        "action_manufacturing_done",
      ],
      pagination: {
        sortBy: "created_at",
        rowsPerPage: 0,
        descending: false,
      },
      columns: [
        {
          name: "macs.eth0",
          required: true,
          label: "MAC1",
          field: "macs.eth0",
          sortable: true,
        },
        {
          name: "entry_key",
          required: true,
          label: "Entry Key",
          field: "entry_key",
          sortable: true,
        },
        {
          name: "usn",
          label: "USN",
          field: "usn",
          sortable: true,
        },
        {
          name: "hw_version",
          label: "HW Version",
          field: "hw_version",
          sortable: true,
        },
        {
          name: "model",
          label: "Model",
          field: "model",
          sortable: true,
        },
        {
          name: "firmware",
          label: "Firmware",
          field: "firmware",
          sortable: true,
        },
        {
          name: "ips.eth0.address",
          required: true,
          label: "IP1",
          field: "ips.eth0.address",
          sortable: true,
        },
        {
          name: "ips.eth1.address",
          label: "IP2",
          field: "ips.eth1.address",
          sortable: true,
        },
        {
          name: "ping_count",
          label: "Ping Count",
          field: "ping_count",
          sortable: true,
        },
        {
          name: "pinged_at",
          label: "Pinged At",
          field: "pinged_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "action_manufacturing_done",
          label: "Manufacturing Done",
          field: "action_manufacturing_done",
          format: (val) => `${val ? "completed" : "in-process"}`,
          sortable: true,
        },
      ],
      secretId: "",
      tableIP: "",
      timeWindow: "",
      manufacturingStatus: "",
      refreshingResidents$i: null,
    };
  },
  computed: {
    residents: function () {
      return Object.values(this.$store.getters.residents)
        .filter((v) => {
          if (!this.tableIP) return true;
          if (String(v["ips.eth0.address"]).startsWith(this.tableIP))
            return true;
          if (String(v["ips.eth1.address"]).startsWith(this.tableIP))
            return true;
          return false;
        })
        .filter((v) => {
          if (!this.timeWindow) return true;
          if (isNaN(+this.timeWindow)) return false;
          const now = new Date().getTime();
          const beginTime = now - 1000 * 60 * +this.timeWindow;
          return +v["pinged_at"] > beginTime;
        })
        .filter((v) => {
          if (!this.manufacturingStatus) return true;
          switch (this.manufacturingStatus) {
            case "in-process":
              return !v["action_manufacturing_done"];
            case "completed":
              return v["action_manufacturing_done"];
            default:
              return false;
          }
        });
    },
    secretIds: function () {
      return this.$store.getters.secretIds;
    },
    tableIPs: function () {
      return Object.keys(this.$store.getters.tableIPs);
    },
    timeWindows: function () {
      return ["5", "10", "30", "60", "1440"];
    },
    manufacturingStatuses: function () {
      return ["in-process", "completed"];
    },
    loading: function () {
      return this.$store.getters.statusGetResidents === "requested";
    },
  },
  watch: {
    secretIds: function (val) {
      if (!val?.length) return;
      if (this.secretId) return;
      this.secretId = val[val.length - 1]; // choose the last one
    },
    secretId: function (val) {
      ims.config.updateLocalConfig("secretId", val);
    },
    tableIP: function (val) {
      ims.config.updateLocalConfig("tableIP", val);
    },
    timeWindow: function (val) {
      ims.config.updateLocalConfig("timeWindow", val);
    },
    manufacturingStatus: function (val) {
      ims.config.updateLocalConfig("manufacturingStatus", val);
    },
  },
  mounted: function () {
    this.init();
    this.startRefreshingResidents();
  },
  beforeUnmount: function () {
    this.stopRefreshingResidents();
  },
  methods: {
    init: function () {
      if (ims.config.localConfig.secretId !== undefined) {
        this.secretId = ims.config.localConfig.secretId;
      }
      if (ims.config.localConfig.tableIP !== undefined) {
        this.tableIP = ims.config.localConfig.tableIP;
      }
      if (ims.config.localConfig.timeWindow !== undefined) {
        this.timeWindow = ims.config.localConfig.timeWindow;
      }
      if (ims.config.localConfig.manufacturingStatus !== undefined) {
        this.manufacturingStatus = ims.config.localConfig.manufacturingStatus;
      }
    },
    //
    getResidents: async function () {
      if (!this.secretIds?.length || !this.secretId) {
        await this.$store.dispatch("getSecretIds");
      }
      return this.$store.dispatch("getResidents", {
        secret_id: this.secretId,
      });
    },
    //
    startRefreshingResidents: function () {
      // INIT
      const interval = 1000 * 60 * 1; // minute(s)
      let lastSuccessful = 0;
      this.stopRefreshingResidents();
      // ACTION
      this.refreshingResidents$i = setInterval(async () => {
        const timeSinceLastSuccessful = new Date().getTime() - lastSuccessful;
        if (timeSinceLastSuccessful < interval) return;
        const res = await this.getResidents();
        if (res) lastSuccessful = new Date().getTime();
      }, 1000 * 2); // second(s) checking interval
    },
    stopRefreshingResidents: function () {
      if (!this.refreshingResidents$i) return;
      clearInterval(this.refreshingResidents$i);
      this.refreshingResidents$i = null;
    },
    //
    openPrintQRCodes: function () {
      this.$c.dialog("print_qrcodes", {
        devices: this.selected,
      });
    },
    openResidentDialog: function (csn) {
      this.$c.dialog("resident", { csn });
    },
  },
};
</script>
