<template lang="pug">
q-table(
  :rows="residents",
  :columns="columns",
  color="primary",
  row-key="csn",
  :filter="filter",
  selection="multiple",
  v-model:selected="selected",
  :visible-columns="visibleColumns",
  :pagination="pagination",
  :rows-per-page-options="[20, 50, 0]",
  :loading="loading"
)
  template(v-slot:top-left)
    .flex.q-gutter-sm.q-mb-sm
      q-select(
        outlined,
        dense,
        clearable,
        v-model="secretId",
        :options="secretIds",
        label="Production ID",
        style="width: 200px"
      )
      q-input(
        outlined,
        dense,
        debounce="300",
        v-model="filter",
        placeholder="Search",
        style="width: 250px"
      )
        template(v-slot:append)
          q-icon(name="search")

  template(v-slot:top-right)
    .flex.q-gutter-sm.q-mb-sm
      q-btn(
        color="primary",
        icon="archive",
        label="CSV",
        :disable="selected.length < 1",
        @click="exportTableCSV"
      )
      q-btn(
        outline,
        color="primary",
        icon="refresh",
        label="Refresh",
        :loading="loading",
        @click="getResidents"
      )

  template(v-slot:header="props")
    q-tr(:props="props")
      q-th
      q-th(auto-width)
        q-checkbox(v-model="props.selected")
      q-th(v-for="col in props.cols", :key="col.name", :props="props")
        | {{ col.label }}

  template(v-slot:body="props")
    q-tr(:props="props")
      q-td
        q-btn(
          color="primary",
          dense,
          flat,
          icon="info_outline",
          @click="openResidentDialog(props?.row?.csn)"
        )
      q-td(auto-width)
        q-checkbox(v-model="props.selected")
      q-td(v-for="col in props.cols", :key="col.name", :props="props")
        //- Environment
        template(v-if="col.label === 'Environment'")
          span.bg-grey-2.text-black.q-py-xs.q-px-sm.rounded-borders(
            v-if="col.value === 'prod'"
          ) {{ col.value }}
          span.bg-warning.text-black.q-py-xs.q-px-sm.rounded-borders(v-else) {{ col.value }}
        //- Manufacturing Done
        template(v-else-if="col.label === 'Manufacturing Done'")
          span.bg-warning.text-black.q-py-xs.q-px-sm.rounded-borders(
            v-if="col.value === 'in-process'"
          ) {{ col.value }}
          span.bg-positive.text-black.q-py-xs.q-px-sm.rounded-borders(
            v-else-if="col.value === 'completed'"
          ) {{ col.value }}
        //- ELSE
        template(v-else)
          | {{ col.value }}
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  data: function () {
    return {
      filter: "",
      selected: [],
      visibleColumns: [
        "csn",
        "entry_key",
        "macs.eth0",
        "macs.eth1",
        "ips.eth0.address",
        "ips.eth1.address",
        "usn",
        "hw_version",
        "model",
        "firmware",
        "ping_count",
        "rpi_model",
        "rpi_revision",
        "os",
        "uptime",
        "boot_count",
        "env",
        "pinged_at",
        "pinged_by_remote_ip",
        "updated_at",
        "updated_by_remote_ip",
        "created_at",
        "created_by_remote_ip",
        "secret_id",
        "action_manufacturing_done",
      ],
      pagination: {
        sortBy: "created_at",
        rowsPerPage: 0,
        descending: false,
      },
      columns: [
        {
          name: "csn",
          required: true,
          label: "Serial Number (Core)",
          align: "left",
          field: (row) => row.csn,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "entry_key",
          label: "Entry Key",
          field: "entry_key",
          sortable: true,
        },
        {
          name: "macs.eth0",
          label: "MAC1",
          field: "macs.eth0",
          sortable: true,
        },
        {
          name: "macs.eth1",
          label: "MAC2",
          field: "macs.eth1",
          sortable: true,
        },
        {
          name: "ips.eth0.address",
          label: "IP1",
          field: "ips.eth0.address",
          sortable: true,
        },
        {
          name: "ips.eth1.address",
          label: "IP2",
          field: "ips.eth1.address",
          sortable: true,
        },
        {
          name: "pass_code",
          label: "Pass Code",
          field: "pass_code",
          sortable: true,
        },
        {
          name: "usn",
          label: "USN",
          field: "usn",
          sortable: true,
        },
        {
          name: "hw_version",
          label: "HW Version",
          field: "hw_version",
          sortable: true,
        },
        {
          name: "model",
          label: "Model",
          field: "model",
          sortable: true,
        },
        {
          name: "firmware",
          label: "Firmware",
          field: "firmware",
          sortable: true,
        },
        {
          name: "ping_count",
          label: "Ping Count",
          field: "ping_count",
          sortable: true,
        },
        {
          name: "rpi_model",
          label: "RPi Model",
          field: "rpi_model",
          sortable: true,
        },
        {
          name: "rpi_revision",
          label: "RPi Revision",
          field: "rpi_revision",
          sortable: true,
        },
        {
          name: "os",
          label: "OS",
          field: "os",
          sortable: true,
        },
        {
          name: "uptime",
          label: "Uptime",
          field: "uptime",
          sortable: true,
        },
        {
          name: "boot_count",
          label: "Boot Count",
          field: "boot_count",
          sortable: true,
        },
        {
          name: "env",
          label: "Environment",
          field: "env",
          sortable: true,
        },
        {
          name: "pinged_at",
          label: "Pinged At",
          field: "pinged_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "pinged_by_remote_ip",
          label: "Pinged by IP",
          field: "pinged_by_remote_ip",
          sortable: true,
        },
        {
          name: "updated_at",
          label: "Updated At",
          field: "updated_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "updated_by_remote_ip",
          label: "Updated by IP",
          field: "updated_by_remote_ip",
          sortable: true,
        },
        {
          name: "created_at",
          label: "Created At",
          field: "created_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "created_by_remote_ip",
          label: "Created by IP",
          field: "created_by_remote_ip",
          sortable: true,
        },
        {
          name: "secret_id",
          label: "Production ID",
          field: "secret_id",
          sortable: true,
        },
        {
          name: "action_manufacturing_done",
          label: "Manufacturing Done",
          field: "action_manufacturing_done",
          format: (val) => `${val ? "completed" : "in-process"}`,
          sortable: true,
        },
      ],
      secretId: "",
      refreshingResidents$i: null,
    };
  },
  computed: {
    residents: function () {
      return Object.values(this.$store.getters.residents);
    },
    secretIds: function () {
      return this.$store.getters.secretIds;
    },
    loading: function () {
      return this.$store.getters.statusGetResidents === "requested";
    },
  },
  watch: {
    secretIds: function (val) {
      if (!val?.length) return;
      if (this.secretId) return;
      this.secretId = val[val.length - 1]; // choose the last one
    },
    secretId: function (val) {
      ims.config.updateLocalConfig("secretId", val);
    },
  },
  mounted: function () {
    this.init();
    this.startRefreshingResidents();
  },
  beforeUnmount: function () {
    this.stopRefreshingResidents();
  },
  methods: {
    init: function () {
      if (ims.config.localConfig.secretId !== undefined) {
        this.secretId = ims.config.localConfig.secretId;
      }
    },
    //
    getResidents: async function () {
      if (!this.secretIds?.length || !this.secretId) {
        await this.$store.dispatch("getSecretIds");
      }
      return this.$store.dispatch("getResidents", {
        secret_id: this.secretId,
      });
    },
    //
    startRefreshingResidents: function () {
      // INIT
      const interval = 1000 * 60 * 1; // minute(s)
      let lastSuccessful = 0;
      this.stopRefreshingResidents();
      // ACTION
      this.refreshingResidents$i = setInterval(async () => {
        const timeSinceLastSuccessful = new Date().getTime() - lastSuccessful;
        if (timeSinceLastSuccessful < interval) return;
        const res = await this.getResidents();
        if (res) lastSuccessful = new Date().getTime();
      }, 1000 * 2); // second(s) checking interval
    },
    stopRefreshingResidents: function () {
      if (!this.refreshingResidents$i) return;
      clearInterval(this.refreshingResidents$i);
      this.refreshingResidents$i = null;
    },
    //
    exportTableCSV: function () {
      const content = [
        ["model", "mac", "entry_key", "usn", "hardware_version"].join(","),
      ];
      this.selected.forEach((row) => {
        content.push(
          [
            String(row["model"]),
            String(row["macs.eth0"]),
            String(row["entry_key"]),
            String(row["usn"]),
            String(row["hw_version"]),
          ].join(",")
        );
      });

      const filename = `residents-export-${new Date().toISOString()}.csv`;
      const status = ims.exportFile(filename, content.join("\r\n"), "text/csv");
      window.dispatchEvent(
        new CustomEvent("c-export-file", { detail: { status, filename } })
      );
    },
    openResidentDialog: function (csn) {
      this.$c.dialog("resident", { csn });
    },
  },
};
</script>
