<template lang="pug">
q-dialog(ref="dialog", transition-show="slide-up", transition-hide="slide-down")
  q-card(style="max-width: 900px; min-width: 400px")
    //- [HEAD]
    q-bar.bg-grey-5(style="position: sticky; top: 0px; z-index: 1")
      q-icon(name="info_outline")
      div Create New RPI Revision
      q-space
      q-btn(dense, flat, icon="close", v-close-popup)
        q-tooltip Close
    //- [BODY]
    q-card-section.overflow-auto
      //- Editable (critical)
      div
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 Raspberry Pi Related
          .text-body.text-grey-7 For more detail, please check&nbsp;
            a(
              href="https://www.raspberrypi.com/documentation/computers/raspberry-pi.html#new-style-revision-codes-in-use",
              target="_blank",
              rel="noopener noreferrer"
            ) the official document
            | .

        input_102(
          v-model="readwrite['rpi_revision']",
          bg-color="green-1",
          label="rpi_revision (key)*"
        )

      //- CKB related
      .q-mt-md
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 CKB related&nbsp;
            q-icon(name="info")
              q-tooltip
                | CKB304: 1,2 <br/>
                | CKB312: 4,5 <br/>
                | CKB308: 8 <br/>
                | CKB416: 16,17

        input_102(
          v-model.number="readwrite['hw_version']",
          bg-color="green-1",
          type="number",
          label="hw_version (to determine CKB IO spec)*"
        )
        input_102(
          v-model="readwrite['model']",
          bg-color="green-1",
          label="model (to determine CKB IO spec)*"
        )

      //- USN related
      .q-mt-md
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 USN related&nbsp;
            q-icon(name="info")
              q-tooltip
                | CKB304: 50350 <br/>
                | CKB312: 50496 <br/>
                | CKB308: 00000 <br/>
                | CKB416: 00000

        input_102(
          v-model="readwrite['product_code']",
          bg-color="green-1",
          label="product_code (to generate USN of CKB) [5 digits]*"
        )

      //- Additional Information
      .q-mt-md
        .q-mb-sm
          .text-subtitle1.text-bold.text-grey-9 Additional Information
          .text-body.text-grey-7 Just for an additional information.

        input_102(
          v-model="readwrite['model_public']",
          bg-color="amber-1",
          label="model_public (not used)"
        )
        input_102(
          v-model="readwrite['note']",
          bg-color="amber-1",
          label="note (just an additional information)"
        )

        q-btn.full-width.q-mt-sm(
          unelevated,
          label="Create",
          color="positive",
          icon="add",
          :disable="!isValid",
          @click="onClickCreate"
        )
</template>

  <style>
</style>

  <script>
import ims from "../../imports";
import input_102 from "../../input/input_102";
export default {
  components: {
    input_102,
  },
  computed: {
    isValid: function () {
      // *required
      if (this.readwrite["rpi_revision"] === "") return false;
      if (this.readwrite["product_code"] === "") return false;
      if (this.readwrite["hw_version"] < 1) return false;
      if (this.readwrite["model"] === "") return false;
      // not-required
      // if (this.readwrite["model_public"] === "") return false;
      // if (this.readwrite["note"] === "") return false;
      return true;
    },
  },
  data: function () {
    return {
      readwrite: {
        rpi_revision: "",
        product_code: "00000",
        hw_version: 0,
        model: "",
        model_public: "",
        note: "",
      },
    };
  },
  methods: {
    onClickCreate: function () {
      this.$q
        .dialog({
          title: `Create hardware information`,
          message: "Are you sure to create a hardware information?",
          ok: {
            flat: true,
            color: "positive",
          },
          cancel: {
            flat: true,
            color: "black",
          },
          focus: "ok",
        })
        .onOk(async () => {
          await this.createRPIRevision(this.readwrite);
          await ims.tool.sleep(1000 * 1);
          this.getRPIRevisions();
          this.hide();
        });
    },
    createRPIRevision: function ({
      rpi_revision,
      hw_version,
      model,
      model_public,
      note,
      product_code,
    }) {
      return this.$store.dispatch("createRPIRevision", {
        data: {
          rpi_revision,
          hw_version,
          model,
          model_public,
          note,
          product_code,
        },
      });
    },
    getRPIRevisions: function () {
      return this.$store.dispatch("getRPIRevisions");
    },
    hide: function () {
      const ref = this.$refs["dialog"];
      if (!(ref instanceof Object)) return;
      ref.hide();
    },
  },
};
</script>
