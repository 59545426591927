import delay from './delay';
import download from './download';
import ip from './ip';
import object from './object';
import pdf from './pdf';
import qrcode from './qrcode';
import string from './string';
import url from './url';

export default {
  ...delay,
  ...download,
  ...ip,
  ...object,
  ...pdf,
  ...qrcode,
  ...string,
  ...url,
};
