<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  watch: {
    "$route.path": function () {
      console.log(this.$route.path, this.$route.params, this.$route.query);
    },
  },
  mounted: function () {
    console.log("✌️ 7subscribers.pathes loaded");
  },
};
</script>
