import hardwaresRoutes from './hardwares/0index';
import inProductionRoutes from './in-production/0index';
import membersRoutes from './members/0index';
import residentsRoutes from './residents/0index';
import secretsRoutes from './secrets/0index';

const routes = [
  {
    path: '/',
    redirect: '/in-production',
  },
  ...hardwaresRoutes,
  ...inProductionRoutes,
  ...membersRoutes,
  ...residentsRoutes,
  ...secretsRoutes,
];

console.log('✌️ 3routes loaded');

import { createRouter, createWebHistory } from 'vue-router';
export default createRouter({
  history: createWebHistory(),
  routes,
});
