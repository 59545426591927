import ims from '../imports';

// STATE - default
let initialState = {
  residents: null,
  tableIPs: null,
  statusGetResidents: '',
  statusGetResident: '',
  statusUpdateResident: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  residents: function (state) {
    return state.residents ?? {};
  },
  resident: function (state, getters) {
    return function (csn) {
      return getters.residents[csn];
    };
  },
  tableIPs: function (state) {
    return state.tableIPs ?? {};
  },
  statusGetResidents: function (state) {
    return state.statusGetResidents;
  },
  statusGetResident: function (state) {
    return state.statusGetResident;
  },
  statusUpdateResident: function (state) {
    return state.statusUpdateResident;
  },
};

// ACTIONS
const actions = {
  getResidents: async function ({ commit, getters }, { secret_id, mac }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetResidents === 'requested') return;
    commit('GET_RESIDENTS_REQUEST');
    try {
      const res = await ims.models.getResidents({ secret_id, mac });
      commit('GET_RESIDENTS_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_RESIDENTS_FAILURE');
      throw new Error(err);
    }
  },
  getResident: async function ({ commit, getters }, { residentId }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetResident === 'requested') return;
    commit('GET_RESIDENT_REQUEST');
    try {
      const res = await ims.models.getResident({ residentId });
      commit('GET_RESIDENT_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_RESIDENT_FAILURE');
      throw new Error(err);
    }
  },
  updateResident: async function ({ commit, getters }, { residentId, data }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusUpdateResident === 'requested') return;
    commit('UPDATE_RESIDENT_REQUEST');
    try {
      const res = await ims.models.updateResident({ residentId, data });
      commit('UPDATE_RESIDENT_SUCCESS', { residentId, data });
      return res.data;
    } catch (err) {
      commit('UPDATE_RESIDENT_FAILURE');
      throw new Error(err);
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_RESIDENTS_REQUEST: function (state) {
    state.statusGetResidents = 'requested';
  },
  GET_RESIDENTS_SUCCESS: function (state, data) {
    const residents = {};
    const tableIPs = {};
    //
    Object.keys(data).forEach((k) => {
      // UPDATE - residents
      const v = ims.tool.flatifyObject(data[k]);
      const eth0 = ims.tool.padifyIP(v['ips.eth0.address']);
      const eth1 = ims.tool.padifyIP(v['ips.eth1.address']);
      v['ips.eth0.address'] = eth0;
      v['ips.eth1.address'] = eth1;
      residents[k] = v;
      // UPDATE - tableIPs
      const eth0Prefix = ims.tool.extractIPPrefix(eth0);
      const eth1Prefix = ims.tool.extractIPPrefix(eth1);
      if (eth0Prefix) tableIPs[eth0Prefix] = true;
      else if (eth1Prefix) tableIPs[eth1Prefix] = true;
    });
    //
    state.residents = residents;
    state.tableIPs = tableIPs;
    state.statusGetResidents = 'successful';
  },
  GET_RESIDENTS_FAILURE: function (state) {
    state.statusGetResidents = 'failed';
  },
  GET_RESIDENT_REQUEST: function (state) {
    state.statusGetResident = 'requested';
  },
  GET_RESIDENT_SUCCESS: function (state, data) {
    // UPDATE - resident
    const v = ims.tool.flatifyObject(data);
    const eth0 = ims.tool.padifyIP(v['ips.eth0.address']);
    const eth1 = ims.tool.padifyIP(v['ips.eth1.address']);
    v['ips.eth0.address'] = eth0;
    v['ips.eth1.address'] = eth1;
    //
    state.residents[v['csn']] = v;
    state.statusGetResident = 'successful';
  },
  GET_RESIDENT_FAILURE: function (state) {
    state.statusGetResident = 'failed';
  },
  UPDATE_RESIDENT_REQUEST: function (state) {
    state.statusUpdateResident = 'requested';
  },
  UPDATE_RESIDENT_SUCCESS: function (state, { residentId, data }) {
    // FOR live update in the 4services as well without hitting the 5models
    state.residents[residentId] = {
      ...state.residents[residentId],
      ...data,
    };
    state.statusUpdateResident = 'successful';
  },
  UPDATE_RESIDENT_FAILURE: function (state) {
    state.statusUpdateResident = 'failed';
  },
};

export default {
  residents: {
    state,
    getters,
    actions,
    mutations,
  },
};
