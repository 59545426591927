import ims from '../imports';

// STATE - default
let initialState = {
  members: null,
  statusGetMembers: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  members: function (state) {
    return state.members ?? {};
  },
  statusGetMembers: function (state) {
    return state.statusGetMembers;
  },
};

// ACTIONS
const actions = {
  getMembers: async function ({ commit }) {
    commit('GET_MEMBERS_REQUEST');
    try {
      const res = await ims.models.getMembers();
      commit('GET_MEMBERS_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_MEMBERS_FAILURE');
      throw new Error(err);
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_MEMBERS_REQUEST: function (state) {
    state.statusGetMembers = 'requested';
  },
  GET_MEMBERS_SUCCESS: function (state, data) {
    state.members = data;
    state.statusGetMembers = 'successful';
  },
  GET_MEMBERS_FAILURE: function (state) {
    state.statusGetMembers = 'failed';
  },
};

export default {
  members: {
    state,
    getters,
    actions,
    mutations,
  },
};
