import ims from '../imports';

// STATE - default
let initialState = {
  secrets: null,
  statusGetSecrets: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  secrets: function (state) {
    return state.secrets ?? {};
  },
  statusGetSecrets: function (state) {
    return state.statusGetSecrets;
  },
};

// ACTIONS
const actions = {
  getSecrets: async function ({ commit, getters }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetSecrets === 'requested') return;
    commit('GET_SECRETS_REQUEST');
    try {
      const res = await ims.models.getSecrets();
      commit('GET_SECRETS_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_SECRETS_FAILURE');
      throw new Error(err);
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_SECRETS_REQUEST: function (state) {
    state.statusGetSecrets = 'requested';
  },
  GET_SECRETS_SUCCESS: function (state, data) {
    state.secrets = data;
    state.statusGetSecrets = 'successful';
  },
  GET_SECRETS_FAILURE: function (state) {
    state.statusGetSecrets = 'failed';
  },
};

export default {
  secrets: {
    state,
    getters,
    actions,
    mutations,
  },
};
