<template lang="pug">
q-layout.full-height(view="hHh Lpr lFf")
  Header
  Drawer

  q-page-container.full-height(style="background: #e2e8ef")
    router-view
</template>

<style>
</style>

<script>
import Header from "./2header.vue";
import Drawer from "./2drawer.vue";
export default {
  components: {
    Header,
    Drawer,
  },
  mounted: function () {
    console.log("✌️ 3routes.1index.vue loaded");
  },
};
</script>
