import ims from '../../imports';

// STATE - default
let initialState = {
  rpi_revisions: null,
  statusGetRPIRevisions: '',
  statusGetRPIRevision: '',
  statusCreateRPIRevision: '',
  statusUpdateRPIRevision: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  rpi_revisions: function (state) {
    return state.rpi_revisions ?? {};
  },
  rpi_revision: function (state, getters) {
    return function (rpi_revision_id) {
      return getters.rpi_revisions[rpi_revision_id];
    };
  },
  statusGetRPIRevisions: function (state) {
    return state.statusGetRPIRevisions;
  },
  statusGetRPIRevision: function (state) {
    return state.statusGetRPIRevision;
  },
  statusUpdateRPIRevision: function (state) {
    return state.statusUpdateRPIRevision;
  },
};

// ACTIONS
const actions = {
  getRPIRevisions: async function ({ commit, getters }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetRPIRevisions === 'requested') return;
    commit('GET_RPI_REVISIONS_REQUEST');
    try {
      const res = await ims.models.getRPIRevisions();
      commit('GET_RPI_REVISIONS_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_RPI_REVISIONS_FAILURE');
      throw new Error(err);
    }
  },
  getRPIRevision: async function ({ commit, getters }, { rpi_revision_id }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusGetRPIRevision === 'requested') return;
    commit('GET_RPI_REVISION_REQUEST');
    try {
      const res = await ims.models.getRPIRevision({ rpi_revision_id });
      commit('GET_RPI_REVISION_SUCCESS', res.data);
      return res.data;
    } catch (err) {
      commit('GET_RPI_REVISION_FAILURE');
      throw new Error(err);
    }
  },
  createRPIRevision: async function ({ commit, getters }, { data }) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusCreateRPIRevision === 'requested') return;
    commit('CREATE_RPI_REVISION_REQUEST');
    try {
      const res = await ims.models.createRPIRevision({ data });
      commit('CREATE_RPI_REVISION_SUCCESS');
      return res.data;
    } catch (err) {
      commit('CREATE_RPI_REVISION_FAILURE');
      throw new Error(err);
    }
  },
  updateRPIRevision: async function (
    { commit, getters },
    { rpi_revision_id, data }
  ) {
    if (!ims.firebase_auth.isAuthenticated) return;
    if (getters.statusUpdateRPIRevision === 'requested') return;
    commit('UPDATE_RPI_REVISION_REQUEST');
    try {
      const res = await ims.models.updateRPIRevision({ rpi_revision_id, data });
      commit('UPDATE_RPI_REVISION_SUCCESS', { rpi_revision_id, data });
      return res.data;
    } catch (err) {
      commit('UPDATE_RPI_REVISION_FAILURE');
      throw new Error(err);
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_RPI_REVISIONS_REQUEST: function (state) {
    state.statusGetRPIRevisions = 'requested';
  },
  GET_RPI_REVISIONS_SUCCESS: function (state, data) {
    state.rpi_revisions = data;
    state.statusGetRPIRevisions = 'successful';
  },
  GET_RPI_REVISIONS_FAILURE: function (state) {
    state.statusGetRPIRevisions = 'failed';
  },
  GET_RPI_REVISION_REQUEST: function (state) {
    state.statusGetRPIRevision = 'requested';
  },
  GET_RPI_REVISION_SUCCESS: function (state, data) {
    if (!state.rpi_revisions) state.rpi_revisions = {};
    state.rpi_revisions[data['rpi_revision']] = data;
    state.statusGetRPIRevision = 'successful';
  },
  GET_RPI_REVISION_FAILURE: function (state) {
    state.statusGetRPIRevision = 'failed';
  },
  CREATE_RPI_REVISION_REQUEST: function (state) {
    state.statusCreateRPIRevision = 'requested';
  },
  CREATE_RPI_REVISION_SUCCESS: function (state) {
    state.statusCreateRPIRevision = 'successful';
  },
  CREATE_RPI_REVISION_FAILURE: function (state) {
    state.statusCreateRPIRevision = 'failed';
  },
  UPDATE_RPI_REVISION_REQUEST: function (state) {
    state.statusUpdateRPIRevision = 'requested';
  },
  UPDATE_RPI_REVISION_SUCCESS: function (state, { rpi_revision_id, data }) {
    // FOR live update in the 4services as well without hitting the 5models
    state.rpi_revisions[rpi_revision_id] = {
      ...state.rpi_revisions[rpi_revision_id],
      ...data,
    };
    state.statusUpdateRPIRevision = 'successful';
  },
  UPDATE_RPI_REVISION_FAILURE: function (state) {
    state.statusUpdateRPIRevision = 'failed';
  },
};

export default {
  rpi_revisions: {
    state,
    getters,
    actions,
    mutations,
  },
};
