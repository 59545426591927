import hardwares from './hardwares';
import members from './members';
import residents from './residents';
import secretIds from './secretIds';
import secrets from './secrets';

console.log('✌️ 5models loaded');

export default {
  ...hardwares,
  ...members,
  ...residents,
  ...secretIds,
  ...secrets,
};
