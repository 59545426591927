<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  created: function () {
    console.log("✌️ 7subscribers.errors loaded");
    //
    window.removeEventListener("unhandledrejection", this.handleUR);
    window.addEventListener("unhandledrejection", this.handleUR);
    window.removeEventListener("error", this.handleUE);
    window.addEventListener("error", this.handleUE);
  },
  methods: {
    handleUR: function (event) {
      console.warn(event.reason.stack);
      this.$q.notify({
        message: `UNHANDLED PROMISE REJECTION: ${event.reason}`,
        color: "warning",
        icon: "warning",
      });
      // prevent unnecessary console log
      event.preventDefault();
    },
    handleUE: function (event) {
      // 1. LOG
      this.$q.notify({
        message: `UNCAUGHT EXCEPTION: ${event.type}: ${event.message}`,
        color: "negative",
        icon: "warning",
      });
      // prevent web app to go down
      event.preventDefault();
      // 2. sendMailToAdminIfCritical
      // 3. saveInOpsQueueIfCritical
      // 4. determineIfOperationalError
    },
  },
};
</script>
