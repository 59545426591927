<template lang="pug">
q-input(
  stack-label,
  filled,
  readonly,
  :label-color="!modelValue ? 'grey-2' : ''",
  :bg-color="modelValueChanged ? 'amber-3' : !modelValue ? 'grey-7' : ''",
  :modelValue="modelValue",
  @update:modelValue="updateModelValue"
)
</template>

<style>
</style>

<script>
export default {
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data: function () {
    return {
      modelValueChanged: false,
      prevModelValue: null,
    };
  },
  watch: {
    modelValue: function (val) {
      if (this.prevModelValue === val) {
        this.modelValueChanged = false;
        return;
      }
      this.prevModelValue = val;
      this.modelValueChanged = true;
    },
  },
  methods: {
    updateModelValue: function (e) {
      this.$emit("update:modelValue", e);
    },
  },
};
</script>
