<template lang="pug">
q-dialog.full-height(
  ref="dialog",
  persistent,
  maximized,
  transition-show="slide-up",
  transition-hide="slide-down"
)
  q-card.column.full-height
    //- [HEAD]
    q-bar
      q-icon(name="qr_code")
      div Print QR Codes
      q-space
      q-btn(dense, flat, icon="close", v-close-popup)
        q-tooltip Close
    //- [BODY]
    q-card-section.col
      .row(style="height: 100%")
        //- Layout Configurations
        .q-mr-md(style="width: 300px")
          q-select(
            v-model="layout.paper.size",
            :options="paperSizes",
            filled,
            style="max-width: 300px",
            label="paper.size"
          )
          q-input(
            v-model.number="layout.paper.margins.top",
            type="number",
            filled,
            style="max-width: 300px",
            label="paper.margins.top (mm)"
          )
          q-input(
            v-model.number="layout.paper.margins.bottom",
            type="number",
            filled,
            style="max-width: 300px",
            label="paper.margins.bottom (mm)"
          )
          q-input(
            v-model.number="layout.paper.margins.left",
            type="number",
            filled,
            style="max-width: 300px",
            label="paper.margins.left (mm)"
          )
          q-input(
            v-model.number="layout.paper.margins.right",
            type="number",
            filled,
            style="max-width: 300px",
            label="paper.margins.right (mm)"
          )
          q-input(
            v-model.number="layout.qrcode.gap.x",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.gap.x (mm)"
          )
          q-input(
            v-model.number="layout.qrcode.gap.y",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.gap.y (mm)"
          )
          q-input(
            v-model.number="layout.qrcode.size",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.size (mm)"
          )
          q-input(
            v-model.number="layout.qrcode.margin",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.margin (mm)"
          )

        //- Device List
        q-list.q-mr-md.rounded-borders(bordered, style="width: 300px")
          q-item(
            clickable,
            v-ripple,
            v-for="(v, i) in devices",
            :active="selectedCSN === v.csn",
            active-class="selected-csn",
            @click="selectedCSN === v.csn ? (selectedCSN = '') : (selectedCSN = v.csn)"
          )
            q-item-section(avatar)
              template(v-if="selectedCSN !== v.csn")
                .bg-black.q-py-xs.text-center.text-white.rounded-borders(
                  style="width: 28px"
                )
                  | {{ i + 1 }}
              template(v-else)
                .bg-primary.q-py-xs.text-center.text-white.rounded-borders.blink(
                  style="width: 28px"
                )
                  q-icon(name="lightblub", size="sm")
            q-item-section {{ v['macs.eth0'] }}

        //- QR Code PDF document
        iframe.col(:src="iframeSrc", style="height: 100%")
</template>

<style scoped>
.selected-csn {
  color: white;
  background: #ffc107;
}
.blink {
  animation: blink-animation 2s steps(4, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>

<script>
import ims from "../imports";
export default {
  props: {
    devices: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    paperSizes: function () {
      return ["A4", "B4", "LETTER"];
    },
  },
  data: function () {
    return {
      selectedCSN: "",
      qrcodes: [],
      iframeSrc: "",
      layout: {
        paper: {
          size: "A4",
          margins: {
            bottom: 9,
            left: 20,
            right: 20,
            top: 8,
          },
        },
        qrcode: {
          size: 40,
          gap: {
            x: 3,
            y: 0,
          },
          margin: 6,
        },
      },
    };
  },
  watch: {
    devices: function () {
      this.updateQRCodes();
    },
    selectedCSN: function () {
      this.updateQRCodes();
      this.updateResidentBlinkLEDs();
    },
    qrcodes: function () {
      this.generatePDF();
    },
    "layout.paper.size": function () {
      this.generatePDF();
    },
    "layout.paper.margins.top": function () {
      this.generatePDF();
    },
    "layout.paper.margins.bottom": function () {
      this.generatePDF();
    },
    "layout.paper.margins.left": function () {
      this.generatePDF();
    },
    "layout.paper.margins.right": function () {
      this.generatePDF();
    },
    "layout.qrcode.size": function () {
      this.generatePDF();
    },
    "layout.qrcode.gap.x": function () {
      this.generatePDF();
    },
    "layout.qrcode.gap.y": function () {
      this.generatePDF();
    },
    "layout.qrcode.margin": function () {
      this.generatePDF();
    },
  },
  mounted: function () {
    this.updateQRCodes();
  },
  methods: {
    generatePDF: async function () {
      // A4
      // 595.28 x 841.89 pixels?
      // 210.00 x 297.00 mm
      const magicNumber = 595.28 / 210;
      this.iframeSrc = await ims.tool.generatePDFwQRCodes({
        qrcodes: this.qrcodes,
        layout: {
          // mm to pixels
          paper: {
            size: this.layout.paper.size,
            margins: {
              bottom: this.layout.paper.margins.bottom * magicNumber,
              left: this.layout.paper.margins.left * magicNumber,
              right: this.layout.paper.margins.right * magicNumber,
              top: this.layout.paper.margins.top * magicNumber,
            },
          },
          qrcode: {
            size: this.layout.qrcode.size * magicNumber,
            gap: {
              x: this.layout.qrcode.gap.x * magicNumber,
              y: this.layout.qrcode.gap.y * magicNumber,
            },
            margin: this.layout.qrcode.margin * magicNumber,
          },
        },
      });
      // ims.tool.downloadURL(url, "test.pdf");
    },
    updateQRCodes: async function () {
      this.qrcodes = [];
      if (!this.devices?.length) return;
      const qrcodes = [];
      this.devices.forEach((v) => {
        const mac = v["macs.eth0"];
        const entry = v["entry_key"];
        const png = ims.tool.generateQRCodePNG(`${mac}/${entry}`);
        qrcodes.push({
          text_top: mac,
          text_bottom: entry,
          selected: v.csn === this.selectedCSN,
          png,
        });
      });
      this.qrcodes = qrcodes;
    },
    updateResidentBlinkLEDs: function () {
      if (!this.selectedCSN) return;
      return this.$store.dispatch("updateResident", {
        residentId: this.selectedCSN,
        data: {
          action_blink_leds: 30,
        },
      });
    },
  },
};
</script>
