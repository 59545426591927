<template lang="pug">
q-table(
  title="Secrets",
  :rows="secrets",
  :columns="columns",
  color="primary",
  row-key="name",
  :filter="filter",
  :visible-columns="visibleColumns",
  :pagination="pagination",
  :rows-per-page-options="[20, 50, 0]"
)
  template(v-slot:top-right)
    q-input(
      outlined,
      debounce="300",
      v-model="filter",
      placeholder="Search",
      style="width: 350px"
    )
      template(v-slot:append)
        q-icon(name="search")
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  data: function () {
    return {
      filter: "",
      visibleColumns: [
        "id",
        "issued_by",
        "valid_until",
        "updated_at",
        "updated_by_remote_ip",
        "created_at",
        "created_by_remote_ip",
      ],
      pagination: {
        sortBy: "created_at",
        rowsPerPage: 0,
        descending: false,
      },
      columns: [
        {
          name: "id",
          required: true,
          label: "Secret ID",
          align: "left",
          field: (row) => row.id,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "secret",
          label: "Secret",
          field: "secret",
          sortable: true,
        },
        {
          name: "issued_by",
          label: "Issued By",
          field: "issued_by",
          sortable: true,
        },
        {
          name: "valid_until",
          label: "Valid Until",
          field: "valid_until",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "updated_at",
          label: "Updated At",
          field: "updated_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "updated_by_remote_ip",
          label: "Updated by IP",
          field: "updated_by_remote_ip",
          sortable: true,
        },
        {
          name: "created_at",
          label: "Created At",
          field: "created_at",
          format: (val) =>
            `${ims.moment
              .unix(val / 1000)
              .milliseconds(0)
              .toISOString(true)}`,
          sortable: true,
        },
        {
          name: "created_by_remote_ip",
          label: "Created by IP",
          field: "created_by_remote_ip",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    secrets: function () {
      return Object.values(this.$store.getters.secrets);
    },
  },
  mounted: async function () {
    await ims.tool.sleep(1000 * 1);
    await this.getSecrets();
  },
  methods: {
    getSecrets: function () {
      return this.$store.dispatch("getSecrets");
    },
  },
};
</script>
