import qr from 'qr-image';

export default {
  generateQRCode: function (text, type) {
    if (!type) type = 'png';
    return qr.imageSync(text, {
      type,
      size: 40,
      // [error correction level]
      // L: 25x25 array
      // M: 26x26 array
      // Q: 28x28 array
      // H: 29x29 array
      ec_level: 'H',
      margin: 0,
    });
  },
  generateQRCodePNG: function (text) {
    return this.generateQRCode(text, 'png');
  },
  generateQRCodePNGwCKB: function ({ mac, entry }) {
    const png = this.generateQRCodePNG(`${mac}/${entry}`);
    return {
      text_top: mac,
      text_bottom: entry,
      selected: false,
      png,
    };
  },
  generateQRCodeSVG: function (text) {
    return qr.svgObject(text, {
      size: 40,
      // [error correction level]
      // L: 25x25 array
      // M: 26x26 array
      // Q: 28x28 array
      // H: 29x29 array
      ec_level: 'H',
      margin: 0,
    });
  },
};
