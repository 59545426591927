<template lang="pug">
q-dialog(ref="dialog", transition-show="slide-up", transition-hide="slide-down")
  q-card.column.full-height(style="max-width: 900px")
    //- [HEAD]
    q-bar
      q-icon(name="info_outline")
      div CKB Information
      q-space
      q-btn(
        dense,
        flat,
        icon="refresh",
        @click="getResident",
        :loading="loading"
      )
      q-btn(dense, flat, icon="close", v-close-popup)
        q-tooltip Close
    //- [BODY]
    q-card-section.col.overflow-auto
      .row.q-gutter-md
        //- Layout
        div(style="width: 300px")
          //- CKB Manufacturing
          div
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 CKB Manufacturing
              .text-body.text-grey-7 Information provided by&nbsp;
                span.bg-positive.text-black.q-pa-xs.rounded-borders fty.chekt.com
                | .
            input_101(
              v-model="readwrite['usn']",
              bg-color="green-1",
              label="usn"
            )
            input_101(
              v-model="readwrite['entry_key']",
              bg-color="green-1",
              label="entry_key"
            )
            input_101(
              v-model="readwrite['pass_code']",
              type="password",
              label="pass_code"
            )
            input_101(v-model="readwrite['hw_version']", label="hw_version")
            input_101(v-model="readwrite['model']", label="model")
            q-select(
              v-model="readwrite['env']",
              :options="['prod', 'dev']",
              filled,
              label="env (development purpose only)",
              :bg-color="readwrite['env'] === 'prod' ? '' : 'warning'",
              color="black"
            )

          //- CKB HW
          .q-mt-md
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 CKB HW
              .text-body.text-grey-7 Information provided by CKB Hardware.
            input_101(v-model="readwrite['csn']", label="csn")
            input_101(
              v-model="readwrite['rpi_revision']",
              label="rpi_revision"
            )
            input_101(v-model="readwrite['rpi_model']", label="rpi_model")
            input_101(
              v-model="readwrite['macs.eth0']",
              bg-color="green-1",
              label="eth0.MAC"
            )
            input_101(v-model="readwrite['macs.eth1']", label="eth1.MAC")

          //- CKB SW
          .q-mt-md
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 CKB SW
              .text-body.text-grey-7 Information provided by CKB Software.
            input_101(v-model="readwrite['firmware']", label="firmware")
            input_101(v-model="readwrite['os']", label="os")
            input_101(v-model="readwrite['ips.eth0.address']", label="eth0.IP")
            input_101(v-model="readwrite['ips.eth1.address']", label="eth1.IP")
            input_101(v-model="readwrite['uptime']", label="uptime")
            input_101(v-model="readwrite['boot_count']", label="boot_count")
            input_101(v-model="readwrite['ping_count']", label="ping_count")
            input_101(v-model="readwrite['pinged_at']", label="pinged_at")
            input_101(
              v-model="readwrite['pinged_by_remote_ip']",
              label="pinged_by_remote_ip"
            )
            input_101(v-model="readwrite['updated_at']", label="updated_at")
            input_101(
              v-model="readwrite['updated_by_remote_ip']",
              label="updated_by_remote_ip"
            )
            input_101(v-model="readwrite['created_at']", label="created_at")
            input_101(
              v-model="readwrite['created_by_remote_ip']",
              label="created_by_remote_ip"
            )
            input_101(v-model="readwrite['secret_id']", label="secret_id")

        div(style="width: 300px")
          //- Actions
          div
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 Actions
              .text-body.text-grey-7 Actions to complete manufacturing CKB.
            q-select(
              v-model="readwrite['action_blink_leds']",
              :options="[0, 10, 30, 60]",
              filled,
              label="Blink LEDs",
              :loading="loading",
              :bg-color="readwrite['action_blink_leds'] === 0 ? '' : 'warning'",
              color="grey-9"
            )
            q-select(
              v-model="readwrite['action_manufacturing_done']",
              :options="['false', 'true']",
              filled,
              label="Complete Manufacturing Process",
              :loading="loading",
              :bg-color="readwrite['action_manufacturing_done'] !== 'true' ? 'warning' : 'positive'",
              color="grey-9"
            )

          //- QR Code
          .q-mt-md
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 QR Code
              .text-body.text-grey-7 Print a QR code sticker and put it on the CKB.
            form_qrcode(
              :mac="readwrite['macs.eth0']",
              :entry="readwrite['entry_key']",
              @update:pdfurl="(e) => (pdfurl = e)"
            )
            q-btn.full-width.q-mt-sm(
              outline,
              label="Print QR Code",
              color="positive",
              icon="print",
              @click="printPDFwQRCode"
            )
            q-btn.full-width.q-mt-sm(
              outline,
              label="Open QR Code",
              color="positive",
              icon="open_in_browser",
              @click="openPDFwQRCode"
            )
            q-btn.full-width.q-mt-sm(
              outline,
              label="Download QR Code",
              icon="download",
              color="positive",
              @click="downloadPDFwQRCode"
            )

          //- Registration Info
          .q-mt-md
            .q-mb-sm
              .text-subtitle1.text-bold.text-grey-9 Registration Info
              .text-body.text-grey-7 Information needed when registering the CKB.
            q-btn.full-width.q-mt-sm(
              outline,
              label="Open Info",
              color="positive",
              icon="open_in_browser",
              @click="openInfo"
            )
            q-btn.full-width.q-mt-sm(
              outline,
              label="Download Info(CSV)",
              color="positive",
              icon="download",
              @click="downloadInfo"
            )
</template>

<style>
</style>

<script>
import ims from "../imports";
import input_101 from "../input/input_101";
import form_qrcode from "../form/qrcode";
export default {
  components: {
    input_101,
    form_qrcode,
  },
  props: {
    csn: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  computed: {
    resident: function () {
      return this.$store.getters.resident(this.csn);
    },
    loading: function () {
      if (this.$store.getters.statusGetResidents === "requested") return true;
      if (this.$store.getters.statusGetResident === "requested") return true;
      if (this.$store.getters.statusUpdateResident === "requested") return true;
      return false;
    },
    isManufacturingDone: function () {
      if (this.readwrite.action_manufacturing_done === "true") return true;
      if (this.readwrite.action_manufacturing_done === true) return true;
      return false;
    },
  },
  data: function () {
    return {
      readwrite: {},
      pdfurl: "",
      refreshingResident$i: null,
      dialoging: false,
    };
  },
  watch: {
    csn: function () {
      this.init();
    },
    resident: function () {
      this.init();
    },
    "readwrite.env": function (val, valPrev) {
      if (this.resident?.env === val) return;
      // CASE 1 - --> manufacturing process complete:true
      if (this.isManufacturingDone) {
        this.dialoging = true;
        this.$q
          .dialog({
            title: `Cannot Change Environment`,
            message:
              "You cannot change environment when the manufacturing process is completed. Redo the manufacturing process before requesting to change environment.",
            ok: {
              flat: true,
              color: "negative",
            },
          })
          .onDismiss(() => {
            this.readwrite.env = valPrev;
            this.dialoging = false;
          });
        return;
      }
      // CASE 2 - --> manufacturing process complete:false
      this.dialoging = true;
      this.$q
        .dialog({
          title: "Change Environment?",
          message:
            "This is for a development purpose only. Please do not change this unless you know what you are doing.",
          ok: {
            flat: true,
            color: "negative",
          },
          cancel: {
            flat: true,
            color: "black",
          },
          focus: "cancel",
        })
        .onOk(() => this.updateResidentEnv(val))
        .onCancel(() => (this.readwrite.env = valPrev))
        .onDismiss(() => (this.dialoging = false));
    },
    "readwrite.action_blink_leds": function (val, valPrev) {
      if (this.resident?.action_blink_leds === val) return;
      // CASE 1 - --> manufacturing process complete:true
      if (this.isManufacturingDone) {
        this.dialoging = true;
        this.$q
          .dialog({
            title: `Cannot Blink LEDs`,
            message:
              "You cannot blink LEDs when the manufacturing process is completed. Redo the manufacturing process before requesting the blinking LEDs.",
            ok: {
              flat: true,
              color: "negative",
            },
          })
          .onDismiss(() => {
            this.readwrite.action_blink_leds = valPrev;
            this.dialoging = false;
          });
        return;
      }
      // CASE 2 - --> manufacturing process complete:false
      this.dialoging = true;
      this.$q
        .dialog({
          title: `Blink LEDs for ${val} seconds?`,
          message:
            "It will take about 4 seconds before blinking LEDs start. This will set back to 0 automatically after the blinking LEDs is initiated.",
          cancel: {
            flat: true,
            color: "black",
          },
          focus: "ok",
        })
        .onOk(() => this.updateResidentBlinkLEDs(val))
        .onCancel(() => (this.readwrite.action_blink_leds = valPrev))
        .onDismiss(() => (this.dialoging = false));
    },
    "readwrite.action_manufacturing_done": function (val, valPrev) {
      if (String(this.resident?.action_manufacturing_done) === val) return;
      if (this.resident?.action_manufacturing_done === val) return;
      // CASE 1 - --> manufacturing process complete:true
      this.dialoging = true;
      if (val === "true" || val === true) {
        this.$q
          .dialog({
            title: "Complete the manufacturing process?",
            message:
              "This cannot be reverted. You will not able to modify/control the device from the fty.chekt.com after setting this as completed.",
            cancel: {
              flat: true,
              color: "black",
            },
            persistent: true,
            focus: "cancel",
          })
          .onOk(() => this.updateResidentManufacturingDone(true))
          .onCancel(() => (this.readwrite.action_manufacturing_done = valPrev))
          .onDismiss(() => (this.dialoging = false));
        return;
      }
      // CASE 2 - --> manufacturing process complete:false
      this.$q
        .dialog({
          title: "Redo the manufacturing process?",
          message:
            "Please remember to rewrite the RPi image on SD card or CM4 module before redoing the manufacturing process.",
          ok: {
            flat: true,
            color: "negative",
          },
          cancel: {
            flat: true,
            color: "black",
          },
          persistent: true,
          focus: "cancel",
        })
        .onOk(() => this.updateResidentManufacturingDone(false))
        .onCancel(() => (this.readwrite.action_manufacturing_done = valPrev))
        .onDismiss(() => (this.dialoging = false));
    },
  },
  mounted: function () {
    this.init();
    this.startRefreshingResident();
  },
  beforeUnmount: function () {
    this.stopRefreshingResident();
  },
  methods: {
    init: function () {
      if (!this.resident) return;

      this.readwrite = JSON.parse(JSON.stringify(this.resident));
      this.readwrite["pinged_at"] = ims.moment
        .unix(this.readwrite["pinged_at"] / 1000)
        .milliseconds(0)
        .toISOString(true);
      this.readwrite["updated_at"] = ims.moment
        .unix(this.readwrite["updated_at"] / 1000)
        .milliseconds(0)
        .toISOString(true);
      this.readwrite["created_at"] = ims.moment
        .unix(this.readwrite["created_at"] / 1000)
        .milliseconds(0)
        .toISOString(true);
      this.readwrite["action_manufacturing_done"] = String(
        this.readwrite["action_manufacturing_done"]
      );
    },
    //
    getResident: function () {
      if (!this.csn) return;
      return this.$store.dispatch("getResident", { residentId: this.csn });
    },
    startRefreshingResident: function () {
      this.stopRefreshingResident();
      this.getResident();
      this.refreshingResident$i = setInterval(() => {
        if (this.dialoging) return;
        this.getResident();
      }, 1000 * 10); // 10 seconds
    },
    stopRefreshingResident: function () {
      if (!this.refreshingResident$i) return;
      clearInterval(this.refreshingResident$i);
      this.refreshingResident$i = null;
    },
    //
    updateResident: function (data) {
      if (!this.csn) return;
      return this.$store.dispatch("updateResident", {
        residentId: this.csn,
        data,
      });
    },
    updateResidentEnv: function (val) {
      return this.updateResident({ env: val });
    },
    updateResidentBlinkLEDs: function (val) {
      return this.updateResident({ action_blink_leds: val });
    },
    updateResidentManufacturingDone: function (val) {
      return this.updateResident({ action_manufacturing_done: val });
    },
    //
    confirmManufacturingDoneChange: async function () {
      await ims.tool.sleep(1);
      this.$q
        .dialog({
          title: "Confirm",
          message:
            "Are you sure to complete the process. This cannot be reverted. The device will stop handshaking to fty.chekt.com?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {})
        .onCancel(() => {});
    },
    //
    printPDFwQRCode: async function () {
      ims.tool.printURLwPDF(this.pdfurl);
    },
    openPDFwQRCode: async function () {
      ims.tool.openNewTab(this.pdfurl);
    },
    downloadPDFwQRCode: async function () {
      ims.tool.downloadURL(
        this.pdfurl,
        `${this.readwrite["macs.eth0"]}_${this.readwrite["entry_key"]}.pdf`
      );
    },
    openInfo: async function () {
      const htmlDoc = `
      <style>
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 500px;
      }
      td, th {
        border: 1px solid #ddd;
        text-align: left;
        padding: 14px;
      }
      th {
        background-color: #eee;
      }
      tr td:first-child {
        background-color: #eee;
      }
      </style>
      <div style="width:100%;display:flex;flex-direction:row;justify-content:center;align-items:center;">
        <table>
          <tr><td>Mac Address</td><td>${String(
            this.resident["macs.eth0"]
          )}</td></tr>
          <tr><td>Entry Key</td><td>${String(
            this.resident["entry_key"]
          )}</td></tr>
          <tr><td>Model</td><td>${String(this.resident["model"])}</td></tr>
          <tr><td>USN</td><td>${String(this.resident["usn"])}</td></tr>
          <tr><td>Hardware Version</td><td>${String(
            this.resident["hw_version"]
          )}</td></tr>
        </table>
      </div>
      `;
      ims.tool.openNewPopupHTML(htmlDoc);
    },
    downloadInfo: function () {
      const content = [
        ["model", "mac", "entry_key", "usn", "hardware_version"].join(","),
        [
          String(this.resident["model"]),
          String(this.resident["macs.eth0"]),
          String(this.resident["entry_key"]),
          String(this.resident["usn"]),
          String(this.resident["hw_version"]),
        ].join(","),
      ];

      const filename = `resident-export-${this.resident["macs.eth0"]}.csv`;
      const status = ims.exportFile(filename, content.join("\r\n"), "text/csv");
      window.dispatchEvent(
        new CustomEvent("c-export-file", { detail: { status, filename } })
      );
    },
  },
};
</script>
