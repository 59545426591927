import ims from '../../imports';

export default {
  getSecret: function ({ secretId }) {
    return ims.axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + ims.firebase_auth.getAccessToken(),
      },
      baseURL: ims.config.api.prefix,
      url: `/secrets/${secretId}`,
      responseType: 'json',
      timeout: 1000 * 5,
    });
  },
};
