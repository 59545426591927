<template lang="pug">
q-dialog(
  ref="dialog",
  persistent,
  transition-show="slide-up",
  transition-hide="slide-down"
)
  q-card.column
    //- [HEAD]
    q-bar
      q-icon(name="qr_code")
      div Print QR Code
      q-space
      q-btn(dense, flat, icon="close", v-close-popup)
        q-tooltip Close
    //- [BODY]
    q-card-section.col
      .row(style="height: 100%")
        //- Layout Configurations
        div(style="width: 300px")
          q-input(
            v-model.number="size",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.size (mm)"
          )
          q-input(
            v-model.number="margin",
            type="number",
            filled,
            style="max-width: 300px",
            label="qrcode.margin (mm)"
          )
          //- BTNs
          q-btn.full-width.q-mt-md(
            outline,
            label="Print",
            @click="printPDFwQRCode"
          )
          q-btn.full-width.q-mt-md(
            outline,
            label="Open",
            @click="openPDFwQRCode"
          )
          q-btn.full-width.q-mt-md(
            outline,
            label="Download",
            @click="downloadPDFwQRCode"
          )
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  props: {
    device: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
  },
  data: function () {
    return {
      pdfURL: "",
      qrcode: {},
      size: 40,
      margin: 6,
    };
  },
  watch: {
    device: function () {
      this.updateQRCode();
    },
    qrcode: function () {
      this.generatePDF();
    },
    size: function () {
      this.generatePDF();
    },
    margin: function () {
      this.generatePDF();
    },
  },
  mounted: function () {
    this.updateQRCode();
  },
  methods: {
    generatePDF: async function () {
      const size = this.size;
      const margin = this.margin;
      const qrcode = this.qrcode;
      this.pdfURL = await ims.tool.generatePDFwQRCode({ size, margin, qrcode });
    },
    printPDFwQRCode: async function () {
      ims.tool.printURLwPDF(this.pdfURL);
    },
    openPDFwQRCode: async function () {
      ims.tool.openNewTab(this.pdfURL);
    },
    downloadPDFwQRCode: async function () {
      ims.tool.downloadURL(
        this.pdfURL,
        `${this.qrcode.text_top}_${this.qrcode.text_bottom}.pdf`
      );
    },
    updateQRCode: async function () {
      if (!this.device) return;
      const mac = this.device["macs.eth0"];
      const entry = this.device["entry_key"];
      this.qrcode = ims.tool.generateQRCodePNGwCKB({ mac, entry });
    },
  },
};
</script>
